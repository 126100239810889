'use strict';

const modal = require('./modal');
const updater = require('./updater');

/*!--------------------------------------------------------
 * autosuggest
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 * @depends jquery.ui.autocomplete.js
 */
const autosuggest =  (function ($, I18n, undefined) {
  // settings
  var s = {
    locale: 'en',
    selector: '.m-autosuggest',
    name: 'autosuggest',
    selectedItems: [],
    erroredFields: [],
    options: {
      minLength: 2,
      autoFocus: true,
      source: source,
      select: select,
      open: open,
      position: {
        offset: '0 6'
      }
    }
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    // adds autocomplete functionality to
    // all autocomplete fields within context
    $.subscribe('/updater/add', function (context) {
      add(context);
    });

    // initModules is called on page load
    // and /add so when autosuggest hasn't been
    // initialised but is required we publish
    // this specific /add
    $.subscribe('/updater/add/autosuggest', add);

    // destroys autocompletes within context
    // (removes any external elements that may
    // have been added to the page)
    $.subscribe('/updater/destroy', function (context) {
      $(context).find(s.selector).autocomplete('destroy');
    });

    $.subscribe('/autosuggest/select', function (e) {
      removeError(e.target);
      // Hides the extended option buttons when we select
      // an autosuggested value
      hideAndDisableExtendedOptions(e.target);
    });

    overridePluginRenderItem();
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds autosuggest functionality to context
  */
  function add(context) {
    $(context).find(s.selector).each(function () {
      var self = this,
        autosuggest = $(this),
        data = autosuggest.data();

      autosuggest
        .autocomplete(s.options)
        .autocomplete('option', 'appendTo', autosuggest.closest('.m-autosuggest-wrapper'))
        .on('keydown.' + s.name, keydown)
        .on('keyup.' + s.name, keyup)
        .data('m.' + s.name, {
          itemSelected: false
        });

      if (data.autosuggestCreate !== undefined) {
        setupHeader(autosuggest);
      }

      $.subscribe('/updater/validate', function () {
        validate(self);
      });

      initExtendedOptions($(self));

    });

    // We initialize the extended options seperate to the
    // above in order to accomodate contols which do not
    // provide autosuggest functionality (i.e. when meddra
    // is disabled).
    $(context).find(s.selector + '-disabled').each(function () {
      var self = this;
      initExtendedOptions($(self));
    });
  }

  /* --------------------------------------------------------
   * open
   * --------------------------------------------------------
   * called when the suggestion menu is opened
  */
  function open(e, ui) {
    $(this).siblings('.ui-autocomplete').css('zIndex', 100);
    // TODO: scrollbar
    // $.publish('/updater/add', [$(e.target).autocomplete('widget')]);
  }

  /* --------------------------------------------------------
   * select
   * --------------------------------------------------------
   * called when an item is selected from the autocomplete
  */
  function select(e, ui) {
    var input = $(e.target),
      fieldset = input.closest('fieldset'),
      data = input.data('m.' + s.name) || {};

    if (s.blur) {
      clearTimeout(s.blur);
    }

    if (ui.item && !ui.item.value) {
      setTimeout(function () {
        e.target.value = ui.item.wasValue;
      }, 0);

      addError(e.target);
      return;
    }

    data.itemSelected = true;
    s.selectedItems.push(ui.item.label);
    e.target.value = ui.item.value;

    // set hidden llt_code and medra_code
    $.each(ui.item, function (key, value) {
      var field = fieldset.find('.' + key);
      if (field.length) {
        field.prop('disabled', false)
        field.val(value);
      }
    });

    removeError(e.target);
    $.publish('/autosuggest/select', [e, ui]);
  }

  /* --------------------------------------------------------
   * keydown
   * --------------------------------------------------------
  */
  function keydown(e) {
    var keyCode = $.ui.keyCode,
      data = $(e.target).data('m.' + s.name),
      ui = {};

    // changing the input value
    // so abort ajax request if
    // one is firing
    if (s.req) {
      s.req.abort();
    }

    // if they change the value
    // after selecting one then it's
    // not a selection from the autosuggest
    if (e.keyCode !== keyCode.ENTER &&
      e.keyCode !== keyCode.TAB &&
      data.itemSelected) {
      data.itemSelected = false;
    }
  }

  function keyup(e) {
    var target = $(e.target);

    if (!target.val().trim()) {
      removeError(e.target);
      target.val(target.val().trim());
      validate(e.target);
    }
    let editForm = target.closest('form.edit_form');
    if (editForm.length) {
      $.publish('/autosuggest/form/changed', [editForm]);
    }
  }

  function validate(input, autoError) {
    var $input = $(input),
      data = $input.data('m.' + s.name);

    if (typeof(autoError) === 'undefined') autoError = true;
    if ($input.is(':visible') &&
      $input.val() &&
      data &&
      !data.itemSelected) {
      if (autoError) addError(input);
      return false;
    }
    return true;
  }

  function updateContext(field, context) {
    var modal = field.parents('#m-autosuggest-create');
    if (!context && modal.length) context = modal;
    return context;
  }

  /* --------------------------------------------------------
   * addError
   * --------------------------------------------------------
  */
  function addError(field, msg, context) {
    var $field = $(field);

    msg = msg || I18n.t('javascript.autosuggest_error');
    context = updateContext($field, context);

    if ($.inArray($field, s.erroredFields) < 0) {
      s.erroredFields.push($field.attr('id'));
    }

    $.publish('/updater/error/add', [msg, $field, context]);
    modal.resize();
  }

  /* --------------------------------------------------------
   * removeError
   * --------------------------------------------------------
  */
  function removeError(field, msg, context) {
    var $field = $(field),
      errored = $.inArray($field.attr('id'), s.erroredFields);

    msg = msg || I18n.t('javascript.autosuggest_error');
    context = updateContext($field, context);

    // if this field was an errored field
    // we need to remove it from the errored
    // fields list
    if (errored >= 0) {
      s.erroredFields.splice(errored, 1);
    }

    $.publish('/updater/error/remove', [$field, context]);
    $.publish('/updater/error/remove', [msg, $field]);

    modal.resize();
  }

  /* --------------------------------------------------------
   * source
   * --------------------------------------------------------
   * retrieves values to display in the autosuggest
  */
  function source(req, res) {
    var el = this.element,
      loadingClass = 'ui-autocomplete-loading',
      wrapper = el.closest(updater.selector),
      productCountry = wrapper.find('.product_country select').val(),
      noRecords = [{
        label: I18n.t('javascript.no_match'),
        cssClass: s.selector.slice(1) + '-empty',
        wasValue: el.val(),
        value: '',
        id: ''
      }],

      success = function (data) {
        var records = $.map(data, function (hit) {
          // ignore selected items for now REP-2038
          return $.extend({}, hit, {label: hit.name});
        });

        el.removeClass(loadingClass);

        if (records.length) {
          res(records);
        } else {
          res(noRecords);
        }
      },

      fail = function (jqXHR, textStatus, errorThrown) {
        el.removeClass(loadingClass);

        if (textStatus !== 'abort') {
          res(noRecords);
        }
      };
    if (!el.val().trim()) {
      fail(null, 'abort', null);
    } else {
      s.req = $.get(el.data('endpoint'), {q: req.term, country: productCountry}).done(success).fail(fail);
    }
  }

  /* --------------------------------------------------------
   * removeSelectedItem
   * --------------------------------------------------------
   * removes passed item from s.selectedItems cache
  */
  function removeSelectedItem(item) {
    s.selectedItems = $.grep(s.selectedItems, function (selected, i) {
      return (selected !== item);
    });
  }

  function setupHeader(input) {
    var autocomplete = input.data('autocomplete'),
      header = $([
        '<li class="m-autosuggest-header">',
        '<button class="m-autosuggest-create m-button" type="button">',
        I18n.t('javascript.autosuggest.add_own_description') + ' >',
        '</button>',
        '</li>'
      ].join(''));

    autocomplete._origRenderMenu = autocomplete._renderMenu;

    autocomplete._renderMenu = function (ul, data) {
      autocomplete._origRenderMenu(ul, data);
      $(ul).prepend(header);

      header.on('click', '.m-autosuggest-create', function () {
        // force display of autocomplete box when adding new item
        input.data('field', input.data('field-create'));
        s.creatingCustomValue = true;
        showCustomInput(input);
      });
    };
  }

  /* --------------------------------------------------------
   * setupCustomInput
   * --------------------------------------------------------
  */
  function setupCustomInput($input, next) {
    next = next || function () {
    };

    var causalityWarning = '';

    if ($input.data('causality-warning') === true) {
      causalityWarning = I18n.t('javascript.autosuggest.causality_warning');
    }

    customInput($input, function (input) {
      var form = $([
          '<form name="edit_form" class="edit_form">',
          '<div id="m-autosuggest-create">',
          '<fieldset>',
          input,
          '<div class="causality-warning">',
          causalityWarning,
          '</div>',
          '<div class="m-buttons">',
          '<button class="m-button-next m-button-theme">',
          I18n.t('javascript.autosuggest.save'),
          '</button>',
          '<button class="m-button m-button-prev pull-left" type="button">',
          I18n.t('javascript.autosuggest.cancel'),
          '</button>',
          '</div>',
          '</fieldset>',
          '</div>',
          '</form>'
        ].join('')),
        textarea = form.find('textarea'),
        autosuggest = form.find('.m-autosuggest');

      function keypress(event) {
        var keyCode = $.ui.keyCode;
        if (event.keyCode === keyCode.ENTER) {
          event.preventDefault();
        }
      }

      function keyup() {
        $.publish('/autosuggest/form/changed', [form])
      }

      /* --------------------------------------------------------
       * We need remove line-breaks if user insert it by copy text.
       * --------------------------------------------------------
      */
      function trimValue() {
        var trimmedValue = $(this).val()
          .replace(/(\r\n|\n|\r)/, ' ');
        $(this).val(trimmedValue);
      }

      function addCustomInputError(field) {
        var msg;
        field = field || textarea;
        msg = field === textarea ? I18n.t('javascript.verbatim_term_error') : null;
        addError(field, msg, form);
        modal.resize();
      }

      function removeCustomInputError() {
        removeError(textarea, I18n.t('javascript.verbatim_term_error'), form);
        removeError(textarea, I18n.t('javascript.need_at_least_one_error'), form);
        removeError(textarea, I18n.t('javascript.not_allow_both_error'), form);
        removeError(autosuggest, null, form);
        modal.resize();
      }

      textarea
        .on('keypress', keypress)
        .on('input', trimValue)
        .on('blur, keyup', keyup);

      $.subscribe('/updater/add', function () {
        autosuggest = form.find('.m-autosuggest');
      });

      $.subscribe('/modal/close', removeCustomInputError);

      form.on('click', '.m-button-next', function (event) {
        $.publish('/autosuggest/form/changed', [form])
        if (!form.find('.m-errors').length) {
          saveCustomValue($input, event);
        } else {
          return false;
        }
      });

      form.on('click', '.m-button-prev', function (event) {
        cancelCustomInput($input, event);
      });

      $.publish('/updater/add', [form]);

      $input.data('new-form', form);

      s.customValueHandle = $.subscribe('/updater/submit', modal.resize);

      next(form);
    });
  }

  function customInput(input, callback) {
    var maxLength = input.data('modal-maxlength') || 250,
      endPoint = input.data('endpoint'),
      queryParams = input.data('query-params'),
      field = input.data('field');

    if (field != undefined) {
      updater.fetchTemplate({
        field: field,
        endpoint: endPoint,
        enable_meddra: true
      }).done(function (html) {
        callback([
          '<fieldset>',
          '<legend><span>',
          I18n.t('javascript.autosuggest.select_from_list'),
          '</span></legend>',
          html,
          '</fieldset>',
          '<fieldset>',
          '<legend><span>',
          I18n.t('javascript.autosuggest.edit_verbatim'),
          '</span></legend>',
          '<span class="m-field">',
          '<textarea name="verbatim" rows="3" cols="50" maxlength="' + maxLength + '"></textarea>',
          '</span>',
          '</fieldset>'
        ].join(''));
      });
    } else {
      callback('<span class="m-field"><textarea rows="10" cols="50" maxlength="' + maxLength + '"></textarea></span>');
    }

  }

  /* --------------------------------------------------------
   * showCustomInput
   * --------------------------------------------------------
  */
  function showCustomInput(input, termName, codedValues, oldValues) {
    var form = input.data('new-form'),
      productCountry;

    if (oldValues) {
      productCountry = oldValues['country'];
    } else {
      productCountry = input.closest('.m-updater').find('.product_country select').val();
    }

    if (oldValues && oldValues.llt_code && oldValues.llt_code.length > 0) {
      termName = '';
    } else {
      termName = termName || input.val();
    }

    // REP-2469: need to clean codedValues to support autocode of meddra for public users
    if (input.data('inline-verbatim') === false && oldValues && oldValues.custom === 'true') {
      codedValues = {}
    }

    input.autocomplete('close');

    if (!form) {
      setupCustomInput(input, function (form) {
        $.publish('/autosuggest/create', [form, termName, codedValues, productCountry]);
      });
    } else {
      $.publish('/autosuggest/create', [form, termName, codedValues, productCountry]);
    }
  }

  /* --------------------------------------------------------
   * saveCustomValue
   * --------------------------------------------------------
  */
  function saveCustomValue(input, e) {
    var wrapper = input.parents('.m-updater'),
      form = input.data('new-form'),
      product_country_in_modal = form.find('.product_country select'),
      customTerm = form.find('textarea').val(),
      data = input.data('m.' + s.name) || {},
      target = {
        name: findTarget('custom_name'),
        meddra: findTarget('custom_meddra_version'),
        llt: findTarget('custom_llt_code'),
        source: findTarget('custom_source'),
        custom: findTarget('custom')
      },
      source = {
        name: findSource('term.name') || findSource('custom_name'),
        meddra: findSource('meddra_version'),
        llt: findSource('llt_code'),
        source: findSource('source'),
        custom: "true"
      },
      key;

    function findInput(context, name) {
      return context.find('input[data-path$="' + name + '"]');
    }

    function findTarget(name) {
      return findInput(wrapper, name);
    }

    function findSource(name) {
      return findInput(form, 'coded.' + name).val();
    }

    function findSourceProductCountry() {
      return product_country_in_modal.val();
    }

    function saveProductCountry(context) {
      if (product_country_in_modal.length) {
        var productCountry,
          sourceCountry = findSourceProductCountry();

        if (context.closest(updater.selector + '-parent-template').find('.product_country select').length) {
          productCountry = context.closest(updater.selector + '-parent-template').find('.product_country select');
        } else {
          productCountry = context.find('.product_country select');
        }

        productCountry.each(function () {
          $(this).val(sourceCountry);
        });
      }
    }

    for (key in source) {
      if (source.hasOwnProperty(key) && target.hasOwnProperty(key)) {
        target[key].prop('disabled', false)
        target[key].val(source[key]);
      }
    }
    saveProductCountry(wrapper);

    input.val(customTerm);
    data.itemSelected = true;

    e.target = input.get(0);
    $.publish('/autosuggest/select', [e, {show_edit_button: true}]);
    closeCustomInput(input, e);
    input.data('m.' + s.name, data);
  }

  function cancelCustomInput(input, e) {
    if (input.attr('type') !== 'hidden') input.val('');
    closeCustomInput(input, e);
  }

  /* --------------------------------------------------------
   * closeCustomInput
   * --------------------------------------------------------
  */
  function closeCustomInput(input, e) {
    $.publish('/updater/error/remove', [I18n.t('javascript.need_at_least_one_error'), null]);
    $.publish('/updater/error/remove', [I18n.t('javascript.not_allow_both_error'), null]);
    setupCustomInput(input);
    $.unsubscribe(s.customValueHandle);
    s.creatingCustomValue = false;
    $.publish('/autosuggest/new/close');
  }

  /* --------------------------------------------------------
   * overridePluginRenderItem
   * --------------------------------------------------------
  */
  function overridePluginRenderItem() {
    $.ui.autocomplete.prototype._renderItem_original = $.ui.autocomplete.prototype._renderItem;
    $.ui.autocomplete.prototype._renderItem = function (ul, item) {
      return $('<li />', {
        'class': item.cssClass,
        'data': {'item.autocomplete': item},
        'html': $('<a />').text(item.label)
      }).appendTo(ul);
    };
  }

  /* --------------------------------------------------------
   *
   * Allows for toggle buttons to be added to the autosuggest control to cover
   * the unknown/not_provided options that are sometimes needed. Note that this
   * will NOT currently play nicely with having the body selector enabled as
   * well (and was not part of the requirements).
   *
   * --------------------------------------------------------
  */

  function initExtendedOptions(context) {
    var extendedOptions = context.closest('.autosuggest-control').find('.extended-options');

    if (extendedOptions.length == 0) {
      return; // There are no extended controls so do nothing
    }

    var autoSuggestArea = context.closest('.autosuggest-control').find('.m-field');
    var addAnotherButton = context.closest('.m-updater-multiple').find('.m-updater-add');

    // Finds the html labels and ensure that they are enabled since there is a
    // piece of javascript somewhere that just finds these and disables them
    // with no regards to the context.
    var extendedOptionsLabels = extendedOptions.find('label')
    extendedOptionsLabels.removeClass('disabled');

    // If there are any saved fields, we want to hide the extended option buttons on
    // this control since it should only be be available if in edit or unpopulated.
    var savedFields = context.closest('.m-updater-multiple').find('.m-updater-saved');

    if (savedFields.length > 0) {
      extendedOptionsLabels.addClass('disabled');
      extendedOptionsLabels.addClass('hide');
      return;
    }

    // Set up the on click option to allow us to both select and unselect, and
    // to hide the auto suggest area.
    extendedOptionsLabels
      .on('check.options uncheck.options', ':input', function () {
      nextTick($.proxy(multiToggle, null, autoSuggestArea, extendedOptions));
      nextTick($.proxy(toggleAddAnotherButton, null, addAnotherButton, extendedOptions));
    });
  }

  /*
   * At the bottom of any multiselect control is a button which is used to add another
   * item to the list. We want this TRULY disabled (hence prop('disabled', true/false)
   * so that the user cannot add additional items.
  */
  function toggleAddAnotherButton(addAnotherButton, extendedOptions) {
    var checkedButtonCount = extendedOptions.find(':checked').length;

    if (checkedButtonCount > 0) {
      addAnotherButton.addClass('disabled')
      addAnotherButton.prop('disabled', 'disabled')
    };
  }

  /* --------------------------------------------------------
   * When the user selects/unselects one of the extended option buttons
   * we want to either display or hide the auto suggest.
   * --------------------------------------------------------
  */
  function multiToggle(autoSuggestArea, extendedOptions) {
    var checkedButtonCount = extendedOptions.find(':checked').length;
    if (checkedButtonCount > 0) {
      autoSuggestArea.addClass('hide')
      autoSuggestArea.addClass('disabled')
      // Force the value to empty to prevent a bug where the user might
      // type data into the autosuggest control but then click one of
      // the extended option buttons.
      autoSuggestArea.context.value = ''
    } else {
      autoSuggestArea.removeClass('hide')
      autoSuggestArea.removeClass('disabled')
    };
  }

  /* --------------------------------------------------------
    When the user selects an autosuggested value we want
    to hide the extended option buttons.
   * --------------------------------------------------------
  */
  function hideAndDisableExtendedOptions(control) {
    var extendedOptions = $(control).closest('.autosuggest-control').find('.extended-options');
    extendedOptions.addClass('hide')
    extendedOptions.addClass('disabled')
  }

  /* --------------------------------------------------------
   * helper
   * --------------------------------------------------------
  */
  function nextTick(fn) {
    setTimeout(fn, 0);
  }

  /* --------------------------------------------------------
   * End of extended option toggler
   * --------------------------------------------------------
  */

  return {
    selector: s.selector,
    init: init,
    select: select,
    removeSelectedItem: removeSelectedItem,
    showCustomInput: showCustomInput,
    validate: validate
  };

}(jQuery, window.I18n));

// expose for body selector
window.MMM = window.MMM || {}
window.MMM.autosuggest = autosuggest;
module.exports = autosuggest;
